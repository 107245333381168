@use '../../../lib/colorvars';

.altitudeProfile {
  padding: 74px 24px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    p {
      font-size: 13.5px;
      letter-spacing: -0.1px;
      font-weight: 600;
      color: colorvars.$white;
    }

    .icon {
      margin-right: 7px;
    }

    .facts {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  .subline {
    display: flex;
    justify-content: space-between;
    width: 100%;
    p {
      font-size: 11px;
      color: colorvars.$white;
    }
  }
}
