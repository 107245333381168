@use '../../lib/colorvars';
@use 'sass:color';

.routePropertyController {
  overflow: hidden;
  width: 280px;
  height: auto;
  min-height: 100px;
  position: relative;
}

.tutorial {
  position: absolute;
  top: 0;
  right: 100%;
  margin-top: calc(var(--appTopPadding) + 35px);
}

.buttons {
  display: flex;
  padding: 10px;

  flex-flow: row nowrap;
  justify-content: space-between;

  background: colorvars.$white;
}

.ratioControllers {
  padding: 0 0 20px;

  background: color.change(colorvars.$black, $alpha: 0.3);
  backdrop-filter: blur(7.5px);
}
