@use '../../../lib/colorvars';

.dialog {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 300px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  pointer-events: none;

  .card {
    width: 100%;
    position: absolute;

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-height: 600px;
      padding: 32px 30px 25px 30px;
      pointer-events: auto;
      background-color: #{colorvars.$white};
      background-clip: padding-box;
      border-radius: 10px;
      outline: 0;
      overflow: hidden;

      .close {
        position: absolute;
        display: flex;
        right: 14px;
        top: 10px;
        height: 25px;
        width: 25px;
        align-items: flex-end;
      }

      .icon {
        margin-bottom: 4px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.28px;
        text-align: center;
        margin-bottom: 20px;
        //-moz-osx-font-smoothing: grayscale;
        //-webkit-font-smoothing: antialiased;
      }
    }
  }
}
