@use '../../lib/colorvars' as c;

.waypointSortable {
  position: relative;

  &:hover .dragHandle {
    background: rgba(c.$white, 1);

    .sortIcon {
      transform: scale(1.3);
      opacity: 1;
    }
  }
}

.dragHandle {
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 1;

  display: flex;
  width: 25px;
  height: 100%;

  align-items: flex-start;
  justify-content: center;

  background: rgba(c.$white, 0);

  transition: background 0.5s ease-in-out;

  cursor: grab;
  pointer-events: auto;
}

[data-dragging="true"] .dragHandle {
  cursor: grabbing;
}

  .sortIcon {
    height: 30px;
    opacity: 0;
    transform: scale(0.1);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

