@use '../../../lib/colorvars';

@mixin abs-full() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.routePreview {
  position: relative;
  box-sizing: border-box;
  width: 360px;
  height: 600px;
  overflow: hidden;

  .background {
    .previewImage {
      @include abs-full;
      opacity: 0;
      filter: grayscale(100%) contrast(2.2);
      transition: all cubic-bezier(0.16, 1, 0.3, 1) 1s;
      transform: scale(1.1);

      &.active {
        opacity: 1;
        filter: unset;
        transform: unset;
      }
    }

    .previewImageCustom {
      object-fit: cover;
    }

    .filtered {
      @include abs-full;
    }

    .masked {
      @include abs-full;
      mask: url('../../../assets/images/route-preview/notch-top.png') no-repeat;
    }

    .clipped {
      @include abs-full;
      opacity: 0;
      transition: all cubic-bezier(0.16, 1, 0.3, 1) 1s;
      transform: scale(1.1);

      &.active {
        opacity: 1;
        transform: unset;
      }
    }

    .backdrop {
      @include abs-full;
      background-color: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(17.5px);
    }

    .notch {
      @include abs-full;
      opacity: 0.15;
    }

    .notchLine {
      @include abs-full;
    }
  }

  .metrics {
    position: absolute;
    bottom: 0;
    z-index: 10;
    color: #ffffff;
    width: 100%;
    padding: 0 50px 20px;
  }
}
