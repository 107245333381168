.container {
  position: relative;
  display: flex;
  align-items: stretch;
  line-height: 1.1;
  display: none;

  &.show {
    display: block;
  }

  &.left {
    padding-left: 20px;
  }

  &.right {
    padding-right: 20px;
  }
}

.content {
  padding: 20px 30px;
  background: white;
  border-radius: 6px;
}

.arrow {
  position: absolute;
  top: 12px;
  height: 32px;
  width: 17px;
  left: 4px;

  .right & {
    left: auto;
    right: 4px;
    transform: rotate(180deg);
  }
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin: 0 0 5px;
}

.explanation {
  font-size: 18px;
  line-height: 23px;
  margin: 0 0 10px;
}

.close {
  position: absolute;
  top: 0;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 4;

  &:before {
    content: 'x';
  }

  .left & {
    right: 0;
  }

  .right & {
    right: 20px;
  }
}
