@use '../../lib/colorvars' as c;

div.mapboxgl-popup {
  z-index: 11;
  position: relative;
  filter: drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.12));

  &-content {
    position: relative;
    z-index: 1;
    max-width: unset;
    width: fit-content;
    padding: 7px 10px 10px 10px;
    border-radius: 10px;
    box-shadow: none;
  }

  &-tip {
    border-width: 0;
    animation: entry 0.2s ease-in-out forwards;
  }
}

@keyframes entry {
  to {
    border-width:10px;
  }
}
