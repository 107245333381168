@use '../../../lib/colorvars';

.formGroup {
  margin-bottom: 1rem;

  label {
    display: inline-block;
  }

  .wrap {
    position: relative;
    margin-bottom: 5px;

    .inputField {
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      padding: 0 30px 0 10px;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: 0.27px;
      background-color: #{colorvars.$grey-0};
      background-clip: padding-box;
      border: 1px solid transparent;
      border-radius: 5px;
      transition: border-color .15s ease-in-out, box-shadow .15s;

      &.error {
        border: 1px solid #{colorvars.$red};

        &:focus {
          border: 1px solid #{colorvars.$red};
        }
      }

      &:focus {
        outline: 0;
        border: 1px solid #{colorvars.$black};
      }
    }

    .icons {
      position: absolute;
      top: 10px;
      right: 7px;
    }
  }

  .error {
    min-height: 24px;
    font-size: 14px;
    color: #{colorvars.$red};
  }
}
