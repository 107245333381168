@use '../../lib/colorvars';
@use '../../lib/styles/easing';

.modal {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20000;
}

.modalBackground {
  backdrop-filter: blur(10px);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.modalContent {
  background: #{colorvars.$white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.close {
  position: absolute;
  display: flex;
  right: 14px;
  top: 10px;
  height: 25px;
  width: 25px;
  align-items: flex-end;
  z-index: 20;
}
