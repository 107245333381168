.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  pointer-events: auto;
  grid-area: controls;
  position: relative;
}

.terrainOptions {
  position: absolute;
  top: -86px;
  margin-bottom: 10px;
}

.controls {
  display: flex;
  gap: 15px;
  align-items: flex-end;
}
