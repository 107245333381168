.linkButton {
  display: inline;
  padding: 0;
  border: 0;
  line-height: inherit;
  color: grey;
  text-decoration: underline;
  cursor: pointer;
  background: none;

  &:hover {
    text-decoration: none;
  }
}
