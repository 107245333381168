@use '../../lib/colorvars';
@use 'sass:math';

$circle-width: 114px;
$circle-line-width: 4px;
$marker-width: 20px;

$follow-field-size: $circle-width - $marker-width - $circle-line-width;

.navigationCircleContainer {
  display: grid;
  padding: 20px 0;

  grid-template:
    "top top top" auto
    "left circle right" ($circle-width + $circle-line-width)
    "bottom bottom bottom" auto / 1fr ($circle-width + $circle-line-width) 1fr;
  gap: 10px;
  align-items: center;
}

.labelTop,
.labelRight,
.labelBottom,
.labelLeft {
  color: colorvars.$white;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.1em;
}

.labelTop {
  grid-area: top;

  text-align: center;
}

.labelBottom {
  grid-area: bottom;

  text-align: center;
}

.labelLeft {
  grid-area: left;

  text-align: right;
}

.labelRight {
  grid-area: right;

  text-align: left;
}

.circleField {
  position: relative;
  grid-area: circle;

  width: $circle-width + $circle-line-width;
  height: $circle-width + $circle-line-width;
  border: 0;
  border-radius: 50%;

  background: transparent;

  cursor: pointer;

  &::before {
    position: absolute;
    top: math.div($circle-line-width, 2);
    left: math.div($circle-line-width, 2);

    display: block;
    width: $circle-width;
    height: $circle-width;

    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);

    content: '';
  }
}

.followField {
  position: absolute;
  top: $circle-line-width + math.div($marker-width, 2);
  left: $circle-line-width + math.div($marker-width, 2);

  width: $follow-field-size;
  height: $follow-field-size;

  pointer-events: none;
}

.target {
  --x: 53px;
  --y: 53px;

  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  transform: translate3d(var(--x), var(--y), 0);

  &::before {
    position: absolute;
    top: 0;
    left: 0;

    box-sizing: border-box;
    padding: 1px;
    width: $marker-width;
    height: $marker-width;
    border: 1px solid #74beac;

    border-radius: 50%;
    background: white;
    background-clip: content-box;
    transform: translate(-50%, -50%);
    content: '';
    cursor: pointer;
  }
}

.target {
  transform: translate3d(var(--x), var(--y), 0);

  z-index: 1;

  &::before {
    pointer-events: auto;
  }
}

.follower {
  transform: translate3d(var(--x), var(--y), 0);

  pointer-events: none;

  &::before {
    opacity: 0;
    background: rgba(255, 0, 0, 0.1);
  }
}

.indicator {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  pointer-events: none;
}
