@use '../../lib/colorvars';

.imageTile {
  position: relative;
  width: 65px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  transition: all .4s;
  border: 1px solid transparent;
  cursor: pointer;

  .image {
    border-radius: 5px;
    background-color: lightgray;
    position: relative;
    width: 100%;
    height: 100%;
    background-size: contain;
    transition: all .4s;
    box-sizing: border-box;
    overflow: hidden;

    &:hover {
      transform: scale(1.1);
    }

    &::after {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      opacity: 1;
      background: rgba(255, 255, 255, .2);
      transition: all 0.6s;
    }

    &:hover:after {
      opacity: 0;
    }
  }


  &.active {
    border: 1px solid #{colorvars.$black};
    pointer-events: none !important;

    .image {
      transform: scale(1.25) !important;

      &::after {
        opacity: 0;
      }
    }
  }
}
