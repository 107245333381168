.wizardStep {
  padding: 55px 30px 0;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;

  width: 100%;
  height: 100%;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;

  &.currentScreen {
    transform: translateX(0%);
  }

  &.prevScreen {
    transform: translateX(-100%);
  }
}

.backButton {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  padding: 10px;
  border: 0;
  height: auto;

  align-items: center;
  gap: 5px;

  background: none;

  font-size: 16px;
  line-height: 1em;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
