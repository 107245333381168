.routeImageSelector {
  h4 {
    margin-bottom: 10px;
  }

  .group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    margin-bottom: 14px;
  }
}

.imageUpload {
  margin-left: 14px;
}
