@use "../../../lib/colorvars" as colorvars;

.prefillWithSuggestionsCheckbox {
  position: relative;
  display: flex;
  gap: 8px;
  height: 20px;
  width: 20px;
  background: colorvars.$grey-0;
  border-radius: 5px;
  transition: background 200ms ease-in-out;
  float: right;

  &:hover {
    background: colorvars.$grey-2;
  }
}

.fetchAndPrefillSuggestions {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  padding: 0;
  border: 0;
  background: none;
  outline: 0;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
  }
}

.loadingIcon {
  position: absolute;
  top: 0;
  right: 0;
  height:100%;
  width: 100%;
  display: block;
  transform: rotate(0deg);

  .loading & {
    animation: rotateLoader 1s linear reverse infinite;
  }
}

.label {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 30px;
  color: #{rgba(colorvars.$black, 0.4)};
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.24px;
  cursor: pointer;
  white-space: nowrap;

  :disabled + & {
    cursor: not-allowed;
  }
}

@keyframes rotateLoader {
    100% {
        transform: rotate(360deg);
    }
}
