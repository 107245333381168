@use '../../lib/colorvars' as c;

.contextmenu {
  z-index: 2;
  display: flex;

  min-width: 300px;
  gap: 10px;
  flex-direction: column;
  white-space: nowrap;
}

.wrapper {
  position: relative;
  display: flex;
  height: 0;
  opacity: 0;
  gap: 10px;
  flex-direction: column;
  animation: growHeight 0.3s ease-in-out forwards;
}

.title, .add {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.14px;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out forwards;
}

.add {
  font-weight: 600;
  text-align: left;
  padding-bottom: 6px;
  border-bottom: c.$grey-0 solid 2px;
}

.titleTrash {
  max-width: 90%;
}

.buttonStack {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.buttonText {
  padding-left: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.12px;
  text-align: center;
}

.buttonIcon {
  width: 15px;
  height: 15px;
}

.closeButton {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 2;
  margin: 2px;
  padding: 5px 10px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  color: transparent;
  transition: background 0.2s ease-in-out;
  cursor: pointer;

  &:after {
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 48%;
    left: 22%;
    opacity: 0;
    width: 55%;
    height: 1px;
    background: #000000;
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
    animation: fadeIn 1s ease-out forwards;
  }

  &:after {
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.10);

    &:before,
    &:after {
      transform: rotate(180deg);
    }
  }
}

// Animations
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes growHeight {
  0% {
    height: 0;
    opacity: 0;
  }
50% {
    height: 100px;
    opacity: 0;
}
  100% {
    height: 100px;
    opacity: 1;
  }
}
