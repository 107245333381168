@use '../../lib/colorvars';

.container {
  display: flex;
  flex-direction: column;
  padding: 55px 0 20px;
  height: 100%;
}

.headline {
  margin-bottom: 40px;
  flex-shrink: 1;
}

.label {
  flex-shrink: 1;
}

.inputLabel {
  display: block;
  margin-bottom: 15px;
}

.error {
  flex-shrink: 1;
  min-height: 24px;
  font-size: 14px;
  color: #{colorvars.$red};
  margin-bottom: 0.5rem;
}

.linkImportDescription {
  font-size: 14px;
  line-height: 18px;
  color: rgba(0,0,0,0.5);
  flex-grow: 1;
}

.actions {
  flex-shrink: 1;
}

.inputContainer {
  display: flex;
}

.input {
  display: block;
  margin-bottom: 15px;
  padding: 0 0 0 10px;
  height: 40px;
  border: 0;
  flex-grow: 1;

  border-radius: 5px 0 0 5px;
  background: #f4f4f4;
  outline: 0;
}

.pasteButton {
  display: flex;
  padding: 0 5px;
  width: 40px;
  height: 40px;
  border: 0;
  flex-shrink: 1;

  justify-content: center;
  align-items: center;

  border-radius: 0 5px 5px 0;
  background: #f4f4f4;

  cursor: pointer;

  &:hover {
    background: darken(#f4f4f4, 10%);
  }
}

.pasteButtonLabel {
  // Hides the button, but keeps it accessible
  // Copied from https://www.a11yproject.com/posts/how-to-hide-content/
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.submitButtonLabel {
  display: inline-block;
  margin-right: 10px;
}

.submitButtonIcon {
  animation-name: rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(360deg);
  }
}
