.container {
  position: fixed;
  left: 462px;
  bottom: var(--ui-elements-margin);
  z-index: 10;
  display: inline-flex;
  gap: 6px;
  transform: translateY(-6px);

  @media screen and (min-width: 1150px) {
    left: 50%;
    transform: translate(-50%, -6px);
  }

  button:last-child {
    margin-left: 9px;
  }
}

.redoButton :global(svg) {
  transform: scale(-1, 1);
}
