.wizardStart {
  padding: 55px 0 0;
}

.headline {
  margin-bottom: 40px;
}

.importButton {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  border: 1px solid black;
  border-radius: 10px;
  background: none;
  cursor: pointer;

  &:hover {
    background: #f4f4f4;
    text-decoration: underline;
  }

  &:disabled {
    cursor: not-allowed;
    background: darken(#fff, 5%);
    border-color: lighten(black, 50%);

    &:hover {
      text-decoration: none;
    }
  }
}

.gmapsImportButton {
  margin-bottom: 15px;
}

.gpxImportButton {
  margin-bottom: 25px;
}

.importButtonIcon {
  display: block;

  & :global(svg) {
    display: block;
  }
}

.importButtonLabel {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}

.importButtonLabelArrow {
  display: inline-flex;
  margin-left: 5px;
  width: 15px;
  justify-content: center;
}

.optionsDescription {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0,0,0,0.5);
}
