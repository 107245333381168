.terrainOptions {
  padding: 5px;
  background: white;
  border-radius: 10px;
  display: flex;
  gap: 5px;
  position: relative;
}

.triangleDown {
  position: absolute;
  bottom: -12px;
  left: 152px;
  height: 18px;
}

.terrainOption {
  width: 132px;
  height: 70px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  &:hover {
    cursor: pointer;

    .terrainImage {
      transform: scale(1.1);
    }
  }
}

.terrainImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}

.terrainLabel {
  display: flex;
  height: 19px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(12.5px);
  font-size: 11px;
  color: white;
  background: rgba(0, 0, 0, 0.2);

  &.lightMode {
    background: rgba(0, 0, 0, 0.3);
  }
}
