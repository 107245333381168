@use '../../../lib/colorvars';

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.15px;
  white-space: nowrap;
  padding: 0 10px;
  height: 45px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 7.5px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.12);
  user-select: none;
  transition: all 0.25s ease;

  &.medium {
    height: 35px;
    font-size: 14px;
  }

  &.small {
    height: 35px;
    font-size: 13px;
  }

  @media (hover: hover) {
    &:hover:not(:disabled) {
      background-color: #{colorvars.$red-dark} !important;
    }
  }

  &.primary {
    color: #{colorvars.$white};
    background-color: #{colorvars.$red};
  }

  &.secondary {
    color: #{colorvars.$black};
    background-color: #{colorvars.$white};

    @media (hover: hover) {
      &:hover:not(:disabled) {
        background-color: #{colorvars.$grey-1} !important;
      }
    }
  }

  &.tertiary {
    color: #{colorvars.$white};
    background-color: #{colorvars.$grey-6};
  }

  &.iconOnly {
    padding: 0 1.5px;
  }

  &:disabled {
    position: relative;
    cursor: not-allowed;

    &::after {
      content: "";
      position: absolute;
      top: -1px; // to cover 1px border
      right: -1px;
      bottom: -1px;
      left: -1px;
      border-radius: 7.5px;
      background: #{rgba(colorvars.$grey-0, 0.5)};
      pointer-events: none;
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }
}
