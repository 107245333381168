@use '../../lib/colorvars';

.container {
  display: flex;
  flex-direction: column;
  padding: 55px 0 20px;
  height: 100%;
}

.headline {
  margin-bottom: 40px;
  flex-shrink: 1;
}

.label {
  flex-shrink: 1;
}

.inputContainer {
  margin-bottom: 0.5rem;
}

.inputLabel {
  display: block;
  margin-bottom: 15px;
  flex-shrink: 1;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.15px;
}

.fileNameContainer {
  display: flex;
  height: 40px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  background: #f4f4f4;
  border-radius: 5px;
  cursor: pointer;
}

.fileName {
  display: block;
  flex: 0 1 auto;
  overflow: hidden;
  border: 0;
  outline: 0;
  padding: 0 10px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3em;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fileNameCheckIcon {
  margin: 0 5px 0 0;
}

.input {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: none;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: darken(#fff, 5%);
  }
}

.realInput,
.inputButtonLabel {
  // Hides the button, but keeps it accessible
  // Copied from https://www.a11yproject.com/posts/how-to-hide-content/
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.fileImportDescription {
  font-size: 14px;
  line-height: 18px;
  color: rgba(0,0,0,0.5);
  flex-grow: 1;
}

.error {
  min-height: 24px;
  font-size: 14px;
  color: #{colorvars.$red};
  margin-bottom: 0.5rem;
}
