@use '../../lib/colorvars';

.coloredNotch {
  --background_default: #{colorvars.$red};
  --background_forest: #{colorvars.$forest};
  --background_mountain: #{colorvars.$mountain};
  --background_shore: #{colorvars.$shore};
  --background_country: #{colorvars.$country};
  --background_forest_country: #{colorvars.$notch_forest_country};
  --background_forest_mountain: #{colorvars.$notch_forest_mountain};
  --background_forest_shore: #{colorvars.$notch_forest_shore};
  --background_country_mountain: #{colorvars.$notch_country_mountain};
  --background_country_shore: #{colorvars.$notch_country_shore};
  --background_mountain_shore: #{colorvars.$notch_mountain_shore};
  --background_forest_country_shore: #{colorvars.$notch_forest_country_shore};
  --background_forest_mountain_shore: #{colorvars.$notch_forest_mountain_shore};
  --background_country_mountain_shore: #{colorvars.$notch_country_mountain_shore};
  --background_forest_country_mountain: #{colorvars.$notch_forest_country_mountain};
  --background_full: #{colorvars.$notch_full};

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 65px;

  background: black;
}

.background,
.backgroundAlt {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 1;

  transition: opacity 300ms linear;
}

.background {
  z-index: 1;
}
