@use '../../../lib/colorvars';
@use '../../../lib/styles/helper';

.loader {
  height: 21px;
  width: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: spin360 1s infinite linear;
}

.chevronRight {
  margin-top: 4px;
  margin-left: -6px !important;
}

.download {
  margin-top: 4px;
}

.iconWrap {
  margin-left: 8px;
}
