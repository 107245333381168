@use '../../lib/colorvars';
@import './shared';

.preferences {
  padding: 15px 0 15px 0;
  height: auto;
  width: $preferencesWidth;
  overflow: hidden;
  background: colorvars.$white;
  position: relative;
  border-radius: 10px;
}

.tutorial {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 10000;
}

.importButton {
  padding-left: 56px;
  margin-bottom: 8px;
}

.waypoints {
  position: relative;

  width: 100%;

  &::after {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;

    display: block;
    width: 100%;
    height: 300px;

    background: transparent;

    content: '';

    pointer-events: none;
  }
}

.add_waypoint {
  align-items: center;
  background: colorvars.$white;
  border: 0;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  cursor: pointer;
  padding: 3px 20px 3px 0;
  position: relative;
  text-align: left;
  transition: color 100ms linear;
  width: 100%;
  z-index: 1;
  outline: 0;

  &:disabled {
    color: rgba(0, 0, 0, 0.3);
    cursor: not-allowed;
    pointer-events: all;
  }

  &:not(:disabled):hover {
    color: rgba(0, 0, 0, 1);
  }

  &:not(:disabled):hover .actionIcon {
    opacity: 1;
  }

  & + & {
    margin-top: 10px;
  }

  & + &:before {
    content: '';
    position: absolute;
    height: 1px;
    width: calc(100% - 20px);
    left: 10px;
    top: -6px;
    background: rgba(0,0,0,0.1);
  }
}

.actionIcon {
  flex-grow: 0;
  opacity: 0.5;
  transition: opacity 100ms linear;
  width: 44px;
  display: flex;
  justify-content: center;
  margin-right: 9px;

  & :global(svg) {
    max-width: 100%;
    max-height: 100%;
  }
}

.addIcon :global(svg) {
  display: block;
  width: 18px;
  height: 18px;
}

.importIcon :global(svg) {
  display: block;
  width: 16px;
  height: 16px;
}

.actionLabel {
  flex-grow: 1;
}

.toggle_container {
  position: relative;
  z-index: 3;

  display: flex;
  padding-right: 15px;
  justify-content: space-between;

  background: colorvars.$white;
}
