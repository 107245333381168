@use '../../lib/colorvars';
@use 'sass:color';

.routeFacts {
  overflow: hidden;
  width: 280px;
  height: auto;
  position: relative;
}

.tutorial {
  position: absolute;
  top: 0;
  right: 100%;
}

// a wrapper class for size and position
.wrapper {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;

  background: color.change(colorvars.$black, $alpha: 0.3);
  border-radius: 10px;
  width: 280px;
  height: 192px;
  backdrop-filter: blur(7.5px);
}

// the container class for the whole component
.container {
  --swiper-pagination-color: #{colorvars.$white};
  --swiper-pagination-bullet-inactive-color: #{colorvars.$white};
  --swiper-pagination-bullet-inactive-opacity: 0.4;
  --swiper-pagination-bullet-width: 27px;
  --swiper-pagination-bullet-height: 2px;
  --swiper-pagination-bullet-border-radius: 0;
  --swiper-pagination-bullet-horizontal-gap: 2px;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  color: colorvars.$black;
}

// the upper part of the component containing some facts about the route
.head {
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 24px 20px;

  p {
    color: color.change(colorvars.$black, $alpha: 0.5);
    font-size: 11px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
  }

  .pBig {
    color: colorvars.$black;
    font-size: 13.5px;
    font-weight: 600;

    & > span + span {
      margin-left: 0.25em;
    }
  }

  .duration > .pBig {
    font-size: 20px;
  }
}

// the notch to separate the upper from the lower part
.notch {
  position: absolute;
  top: -0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.notchColor {
  position: absolute;
  top: -0;
  left: 50%;
  transform: translateX(-50%);
  --notchColor: colorvars.$red;
}

// the lower part of the component containing two screens, which can be switched on button click
.body {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200%;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease-in-out;
  user-select: none;

  &.secondScreen {
    transform: translate3d(-50%, 0, 0);
  }
}

.percentageData {
  padding: 64px 0 15px;
}

// screen "percentageData"
.percentageData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 20px;

  .icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
}

