@use '../../lib/colorvars';
@use 'sass:color';

.wrapper {
  position: relative;

  padding: 5px 40px;
  border-bottom: 1px solid color.change(colorvars.$white, $alpha: 0.5);
}

.slider {
  overflow: hidden;
}

.slides {
  display: flex;
  width: 100%;
  transition: transform 0.2s ease-out;
}

.slide {
  display: inline-block;
  padding: 0 15px;
  width: auto;

  opacity: 0.3;

  color: colorvars.$white;
  font-size: 11px;
  line-height: 20px;
  text-align: center;

  transition: opacity 200ms linear;

  cursor: grab;
  user-select: none;

  &.active {
    opacity: 1;
  }
}

.sliderButton {
  position: absolute;
  top: 3px;
  z-index: 2;

  width: 25px;
  height: 25px;
  border: 0;

  opacity: 1;
  background: transparent;
  fill: colorvars.$white;

  line-height: 0;

  transform: scale(0.8);

  transition: transform 100ms ease-in-out, opacity 200ms linear;

  cursor: pointer;

  &:hover {
    transform: scale(1);
  }

  &:disabled {
    opacity: 0.3;

    pointer-events: none;
  }
}

.sliderButtonPrev {
  left: 25px;

  :global(svg) {
    transform: rotate(0.5turn);
  }
}

.sliderButtonNext {
  right: 25px;
}
