@use '../../lib/colorvars';
@use '../../lib/styles/easing';

.importDialog {
  display: flex;
  width: 720px;
  max-height: 100%;
  height: 600px;
}

.image, .importer {
  width: 50%;
}

.image {
  height: 100%;
}

.image :global(img) {
  max-height: 100%;
  max-width: auto;
}

.importer {
  height: 100%;
}

.wizardSteps {
  position: relative;
  overflow: hidden;
  height: 100%;
}
