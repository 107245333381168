.altitudeChart {
  position: relative;

  .hidden {
    visibility: hidden;
  }

  .cartesianGrid {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 4px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    hr {
      background-color: rgba(255, 255, 255, 0.1);
      border: 0 none;
      color: rgba(255, 255, 255, 0.1);
      height: 1px;
    }

    .bottom {
      margin-bottom: 5px;
    }
  }
}
