.mapContainer {
  height: 100%;
}

.map {
  height: 100%;
}

.mapControls {
  position: absolute;
  bottom: 27px;
  left: 25px;
}
