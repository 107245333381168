@use '../../lib/colorvars';

.waypoint {
  position: relative;
  padding: 0 15px 0 10px;
  // width: calc(var(--preferencesWidth, 350px) - 25px);
  background: colorvars.$white;
  pointer-events: auto;

  svg {
    width: 100%;
    height: 100%;
  }
}

.limiter {
  height: 40px;
}

.ui {
  display: flex;
  padding: 5px 0;
  gap: 5px;

  align-items: center;

  &:first-of-type {
    padding-top: 0;
  }
}

.roleIcon,
.removeButtonContainer {
  overflow: hidden;
  flex: 0 0 25px;
  height: 25px;
}

.roleIcon {
  &.start,
  &.end {
    height: 18px;
    fill: colorvars.$red;
  }

  &.stop {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 18px;

    .index {
      position: relative;
      color: colorvars.$red;
      font-size: 10px;
      font-weight: 700;
      line-height: 18px;
      text-align: center;

      &::after {
        position: absolute;
        box-sizing: border-box;
        top: 50%;
        left: 50%;
        width: 18px;
        height: 18px;
        border: 2px solid colorvars.$red;
        border-radius: 50%;
        transform: translate3d(-50%, -50%, 0);
        content: '';
      }
    }
  }
}

.removeButton {
  border: 0;

  opacity: 0.3;
  background: transparent;

  fill: colorvars.$black;

  transition: opacity 100ms linear, color 100ms linear;

  cursor: pointer;

  &:hover {
    opacity: 1;

    fill: colorvars.$red;
  }
}

.titleContainer {
  position: relative;

  flex: 1 1 calc(100% - 60px);

  transform: translate3d(0, 0, 0);
}

.title {
  overflow: hidden;
  padding: 0 10px;
  width: 100%;
  height: 30px;

  border-radius: 5px;
  background: colorvars.$grey-0;

  border: 1px solid transparent;

  font-size: 13.5px;
  line-height: 1.3em;
  text-overflow: ellipsis;

  &:focus {
    border: 1px solid #000;
    outline: none;
  }
}

svg.loadingSpinner {
  position: absolute;
  top: 5px;
  right: 5px;

  width: 20px;
  height: 20px;

  animation: spin 1s infinite linear;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.showingAutocomplete .title {
  border-radius: 5px 5px 0 0;
}

[data-dragging="true"] {
  position: relative;
  z-index: 2;
}

.autocompletePopperContainer {
  z-index: 10000;
}

.autocompleteContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  overflow: hidden;
  width: 100%;
  border: 1px solid #000;
  border-top: 0;

  border-radius: 0 0 5px 5px;
}

.autocompleteItem {
  display: block;
  overflow: hidden;
  padding: 8px 15px 0;
  height: 30px;
  border-top: 1px solid #000;

  background: #fff;

  color: rgba(0, 0, 0, 0.75);
  font-size: 11px;
  line-height: 1.1em;
  letter-spacing: 0.02em;
  white-space: nowrap;
  text-overflow: ellipsis;

  cursor: pointer;
}

.autocompleteItemSelected {
  background: #eee;
}

.title:not(:focus) + .autocompleteContainer {
  //display: none;
}
