@use '../../../lib/colorvars';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 30px 25px 30px;
  width: 360px;
  height: 600px;
  background-color: colorvars.$white;

  .icon {
    margin: 5px 0 25px;
  }

  h3 {
    margin-bottom: 24px;
  }

  p {
    text-align: center;
    line-height: 18px;

    &.desc {
      margin-bottom: 28px;
    }

    &.subhead {
      font-weight: 600;
      margin-bottom: 28px;
    }
  }

  .share {
    display: flex;
    text-align: center;
    gap: 6px;
    background-color: colorvars.$grey-0;
    padding: 7px 5px 0 10px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;

    a {
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      text-decoration-line: underline !important;
    }

    .shareIconWrap {
      position: relative;

      .shareIcon {
        margin: 2px;
        color: colorvars.$black;

        &:hover {
          cursor: pointer;
          color: colorvars.$grey-8;
        }

        &:active {
          transform: scale(0.9);
        }
      }
    }
  }

  .canvasWrap {
    box-sizing: border-box;
    border: 1px solid colorvars.$black;
    border-radius: 8px;
    overflow: hidden;
    padding: 10px 10px 3px 10px;
  }

  .cta {
    margin-top: auto;
    width: 100%;
  }
}
