@use "../../lib/colorvars" as colorvars;

/* Style the accordion section */
.section {
  display: flex;
  flex-direction: column;

  &:first-of-type {
    margin-top: 10px;
  }
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  position: relative;
  background-color: transparent;
  color: colorvars.$black;
  cursor: pointer;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  width: 100%;

  &::after {
    position: absolute;
    top: 0;
    left: 5px;
    right: 5px;
    display: block;
    width: calc( 100% - 2 * 5px); // 5px is the padding on the left and right
    height: 1.5px;
    background-color: colorvars.$grey-0;
    content: "";
  }
}

/* Make underline visible on hover */
.accordion:hover .title {
  text-decoration-color: colorvars.$black;
}

/* Style the accordion content title */
.title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-left: auto;
  text-decoration: underline transparent;
  transition: text-decoration-color 0.3s ease-in-out;
}

/* Style the accordion chevron icon */
.iconContainer {
  display: block;
  height: 25px;
  width: 28px;
}

.icon {
  transform: rotate(0deg);
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.active .icon {
  transform: rotate(180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.content_container {
  height: 0;
  overflow: hidden;
  transition: height 300ms ease-in-out;

  /* Style the accordion content text */
  // @TODO: This doesn't seem to be needed?
  // p {
  //   font-family: "Open Sans", sans-serif;
  //   font-weight: 400;
  //   font-size: 14px;
  //   padding: 18px;
  // }
}
