@use './lib/colorvars';

$remsize: 16px;
$base-line-height: 1.5rem;

$base-font-size: $remsize;

$font-default: 'PorscheNext', Helvetica, Arial, sans-serif;
$base-font-path: './assets/fonts';
$base-image-path: './assets/images';

:root {
  color: colorvars.$black;
  font-family: $font-default;
  font-size: $remsize;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: $base-line-height;
  letter-spacing: 0.02em;
  --ui-elements-margin: 30px;
}

@media (max-width: 1349px) {
  :root {
    --ui-elements-margin: 25px;
  }
}

@font-face {
  font-family: 'PorscheNext';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('#{$base-font-path}/PorscheNextWLa-Regular.woff2') format('woff2'),
  url('#{$base-font-path}/PorscheNextWLa-Regular.woff') format('woff');
}

@font-face {
  font-family: 'PorscheNext';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('#{$base-font-path}/PorscheNextWLa-Italic.woff2') format('woff2'),
  url('#{$base-font-path}/PorscheNextWLa-Italic.woff') format('woff');
}

@font-face {
  font-family: 'PorscheNext';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('#{$base-font-path}/PorscheNextWLa-Thin.woff2') format('woff2'),
  url('#{$base-font-path}/PorscheNextWLa-Thin.woff') format('woff');
}

@font-face {
  font-family: 'PorscheNext';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('#{$base-font-path}/PorscheNextWLa-ThinItalic.woff2') format('woff2'),
  url('#{$base-font-path}/PorscheNextWLa-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'PorscheNext';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('#{$base-font-path}/PorscheNextWLa-SemiBold.woff2') format('woff2'),
  url('#{$base-font-path}/PorscheNextWLa-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'PorscheNext';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('#{$base-font-path}/PorscheNextWLa-Bold.woff2') format('woff2'),
  url('#{$base-font-path}/PorscheNextWLa-Bold.woff') format('woff');
}

@font-face {
  font-family: 'PorscheNext';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('#{$base-font-path}/PorscheNextWLa-BoldItalic.woff2') format('woff2'),
  url('#{$base-font-path}/PorscheNextWLa-BoldItalic.woff') format('woff');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input,
button,
textarea {
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

a {
  font-size: 14px;

  &:link, &:visited, &:active {
    color: colorvars.$black;
  }

  &:hover {
    color: colorvars.$grey-8;
  }
}

p {
  font-size: $base-font-size;
  line-height: 120%;
}

p.medium {
  color: colorvars.$black;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.2px;
  opacity: 0.5;
}

p.small {
  color: colorvars.$black;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.2px;
}

p.x-small {
  color: colorvars.$black;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.2px;
}

h3 {
  font-family: $font-default;
  color: colorvars.$black;
  font-size: 20px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.15px;
}

h4 {
  font-family: $font-default;
  color: colorvars.$black;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.15px;
}

label {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.15px;
  margin-bottom: 0.5rem;
}

.bold {
  font-weight: 600;
}

.hidden {
  display: none !important;
}
