@use '../../../lib/colorvars' as c;

.waypointMarkerContainer {
  z-index: 2;

  &.isDragging {
    .waypointMarker {
      transform: scale(1.1);
      box-shadow: 0 0 0 6px rgba(c.$red, 0.2);

      .icon, .index {
        opacity: 0;
        background: transparent;
        transition-delay: 0s;
      }

      .dragIndicator {
        z-index: 2;
        opacity: 1;
        fill: c.$red;
        transform: translate(-50%, -50%) rotate(0);
        transition-delay: 0.2s;
      }

      &::before {
        border: solid 3px c.$red;
        transform: translate3d(-50%, -50%, 0);
      }
    }
  }
}

.waypointMarker {
  position: relative;
  z-index: 1;
  padding: 10px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(c.$red, 0);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  & .dragIndicator {
    z-index: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 13px;
    height: 13px;
    transform: translate(-50%, -50%) scale(0.1) rotate(-45deg);
    opacity: 0;
    fill: c.$red;
    border-radius: 50%;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    transition-delay: 0s;

    cursor: pointer;
  }

  &::before {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    border: 3px solid c.$red;
    background: c.$white;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1);
    content: "";
    transition: transform 0.2s ease-in-out, border 0.2s ease-in-out;
  }

  &.passBy {
    border: 3px solid c.$red;
    background: c.$white;

    &::before, &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      background: transparent;
      border: 1px solid c.$red;
      border-radius: 50%;
    }

    &::after {
      width: calc(100% + 21px);
      height: calc(100% + 21px);
    }
  }

  &:hover, &.contextMenuOpen {
    box-shadow: 0 0 0 5px rgba(c.$red, 0.2);

    .icon, .index {
      opacity: 0;
      background: transparent;
      transition-delay: 0s;
    }

    .dragIndicator {
      z-index: 2;
      opacity: 1;
      fill: c.$red;
      transform: translate(-50%, -50%) rotate(0);
      transition-delay: 0.2s;
    }

    &::before {
      border: solid 3px c.$red;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  .icon, .index {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    opacity: 1;
    transform: translate(-50%, -50%);
    transition: background 0.2s ease-in-out, opacity 0.2s ease-in-out;
    transition-delay: 0.2s;
  }

  .icon {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;

    svg {
      fill: c.$white;
      width: 100%;
      height: 100%;
    }
  }

  .index {
    color: c.$red;
    font-size: 12px;
    font-weight: 700;
  }
}
