@use '../../../lib/colorvars';
@use '../../../lib/styles/easing';

.root {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100%;
  max-width: 720px;
  margin: 0 auto;
  width: auto;
  pointer-events: none;
}

.card {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.content {
  position: relative;
  display: flex;
  min-width: 720px;
  max-height: 600px;
  pointer-events: auto;
  background-color: #{colorvars.$white};
  background-clip: padding-box;
  outline: 0;
}

.left, .right {
  box-sizing: border-box;
}

.left {
  width: 50%;
}

.image {
  width: 100%;
  height: auto;
}

.right {
  position: relative;
  overflow: hidden;
  width: 360px;
  background-color: #{colorvars.$white};
}

.close {
  position: absolute;
  display: flex;
  right: 14px;
  top: 10px;
  height: 25px;
  width: 25px;
  align-items: flex-end;
  z-index: 20;
}

.steps {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 720px;
  height: 600px;
  overflow: hidden;
}

.form {
  transition: all #{easing.$easeInExpo} 0.5s;

  &.hide {
    filter: grayscale(100%) contrast(2.2);
    transform: translateX(-360px);;
  }
}

.success {
  position: absolute;
  filter: grayscale(100%) contrast(2.2);
  transition: all #{easing.$easeOutExpo} 0.5s 0.5s;
  transform: translateX(360px);

  &.show {
    filter: unset;
    transform: translateX(0px);
  }
}

.error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  background: rgba(0, 0, 0, 0.35);
}
