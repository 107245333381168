@use '../../../lib/colorvars';

.textButton {
  display: inline-flex;
  height: 35px;
  align-items: center;
  padding: 0 10px;
  border-radius: 7px;
  font-weight: 600;
  background: #{colorvars.$white};
  color: #{colorvars.$black};
  border: 0;
  outline: 0;
  cursor: pointer;

  &.disabled {
    background: colorvars.$disabled;
    color: colorvars.$grey-1;
    cursor: not-allowed;
  }
}
