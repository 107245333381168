@use '../../lib/colorvars';
@use '../../lib/styles/helper';


.imageUpload {
  position: relative;
  width: 65px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid transparent;
  transition: all .4s;

  &:hover, &.active {
    border: 1px solid #{colorvars.$black} !important;

    .thumbInner {
      transform: scale(1.25) !important;

      &::after {
        opacity: 0 !important;
      }
    }
  }

  .thumbsWrap {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 65px;
    height: 60px;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    z-index: 2;

    .thumb {
      position: relative;
      display: inline-flex;
      width: 100%;
      height: 100%;

      .thumbInner {
        display: flex;
        /* Fix jumpy snap on start and end of the animation*/
        transform: scale(1.00001);
        transition: all .4s;

        @keyframes fakeImageAnimation {
          from {
            transform: translate3d(0, 0, 0);
          }

          to {
            transform: translate3d(0, 0, 0);
          }
        }

        .image {
          display: block;
          background-color: lightgray;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all .4s;
          overflow: hidden;
          animation: infinite .4s fakeImageAnimation;
        }

        &::after {
          content: "";
          position: absolute;
          display: block;
          height: 100%;
          width: 100%;
          opacity: 1;
          background: rgba(255, 255, 255, .2);
          transition: all 0.6s;
        }

        &:hover:after {
          opacity: 0;
        }
      }

      .thumbButton {
        position: absolute;
        display: flex;
        right: 5px;
        top: 3px;
        width: 12px;
        height: 12px;
        border: 0;
        padding: 0;
        background: none;
        pointer-events: all;
        cursor: pointer;
      }
    }
  }

  .dropzone {
    width: 100%;
    height: 100%;

    .icon {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      background-color: #{colorvars.$grey-1};
      box-sizing: border-box;
      transition: all .4s;
      border: 1px solid transparent;

      &:before,
      &:after {
        content: '';
        position: absolute;
        background-color: black;
        transition: transform 0.25s ease;
      }

      /* Vertical line */
      &:before {
        top: calc(50% - 6px);
        left: 50%;
        width: 1px;
        height: 13px;
      }

      /* horizontal line */
      &:after {
        top: 50%;
        left: calc(50% - 6px);
        width: 13px;
        height: 1px;
      }

      &:hover {
        cursor: pointer;

        &:before {
          transform: translateY(0px) scaleY(1.4);
        }

        &:after {
          transform: translateY(0px) scaleX(1.4);
        }
      }
    }
  }

  .loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), lightgray 50% / cover no-repeat;

    .loaderIcon {
      animation: spin360 1s infinite linear;
    }
  }
}
