$black: #000;
$black-20: #00000033;
$white: #fff;
$white-50: #ffffff80;
$red: #d5001c;
$red-dark: #960014;
$petrol: #196a99;
$grey-0: #f4f4f4;
$grey-1: #e3e4e5;
$grey-2: #c9cacb;
$grey-3: #b0b1b2;
$grey-4: #96989a;
$grey-5: #7c7f81;
$grey-6: #626669;
$grey-7: #4a4e51;
$grey-8: #323639;
$forest: #74beac;
$mountain: #9b8b7d;
$country: #aec98d;
$shore: #59739b;
$disabled: $grey-6;


// colorgradients for colored notch
$notch_default: $red;
$notch_forest: $forest;
$notch_mountain: $mountain;
$notch_shore: $shore;
$notch_country: $country;
$notch_forest_country: #{linear-gradient(90deg, #D4F6AA 36.23%, $country 45.17%, $forest 64.29%, $forest 74.54%, #66A999 87.2%)};
$notch_forest_mountain: #{linear-gradient(90deg, #99FFE6 25.23%, $forest 38.96%, $mountain 57.33%, #6A5E54 74.74%)};
$notch_forest_shore: #{linear-gradient(90deg, $forest 26.46%, $shore 73.85%)};
$notch_country_mountain: #{linear-gradient(90deg, #DFFABE 25.98%, $country 44.15%, $mountain 56.51%, #6A5E54 74.95%)};
$notch_country_shore: #{linear-gradient(90deg, #DAFCB0 25.57%, $country 37.87%, $shore 58.28%, #3E5271 76.04%)};
$notch_mountain_shore: #{linear-gradient(90deg, #BEA793 27.83%, $mountain 43.67%, $shore 63.88%, #3E5271 75.01%)};
$notch_forest_country_shore: #{linear-gradient(90deg, #C8E7A3 26.71%, $country 40.91%, $forest 58%, $shore 70.14%, #445877 75.19%)};
$notch_forest_mountain_shore: #{linear-gradient(90deg, #8DE4CF 24.86%, $forest 32.28%, $mountain 47.5%, #445877 72.63%)};
$notch_country_mountain_shore: #{linear-gradient(90deg, #C6E3A2 24.67%, $country 33.25%, $mountain 49.23%, $shore 67.94%, #445877 76.61%)};
$notch_forest_country_mountain: #{linear-gradient(90deg, #C6E3A2 37.65%, $forest 54.07%, $mountain 71.41%)};
$notch_full: #{linear-gradient(90deg, #C8E7A3 26.71%, $country 40.91%, $forest 58%, $shore 70.14%, #445877 75.19%)};
