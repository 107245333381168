@use '../../lib/colorvars';

.metric {
  .value {
    font-size: 15px;
    font-weight: 600;
    line-height: 120%;
    text-align: center;

    &.medium, &.medium > span {
      font-size: 20px;
      line-height: 120%;
    }

    &.large, &.large > span {
      font-size: 22px;
      line-height: 100%;
    }
  }

  .label {
    font-size: 13.5px;
    line-height: 120%;
    text-align: center;
    opacity: 0.5;
  }
}
