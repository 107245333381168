@use '../../../lib/colorvars';

.container {
  border: 0;
  padding: 0;
  outline: 0;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  position: relative;
  background: colorvars.$white;
  transition: background 0.2s ease-in-out;

  @media (hover: hover) {
    &:hover:not(:disabled) {
      cursor: pointer;
      background: colorvars.$grey-1;
    }
  }

  &:disabled {
    position: relative;
    color: inherit;
    cursor: not-allowed;

    &::after {
      content: "";
      position: absolute;
      top: -1px; // to cover 1px border
      right: -1px;
      bottom: -1px;
      left: -1px;
      border-radius: 7.5px;
      background: #{rgba(colorvars.$grey-0, 0.5)};
      pointer-events: none;
    }
  }

  &.active {
    background: colorvars.$red;
    color: colorvars.$white;

    @media (hover: hover) {
      &:hover:not(:disabled) {
        background: colorvars.$red-dark;
      }
    }
  }

  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    &:before {
      display: none;
    }
  }

  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
