.closeButton {
  display: block;
  width: 30px;
  height: 30px;
  background: transparent;
  position: absolute;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:after {
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
  }

  &:before,
  &:after {
    content: '';
    width: 55%;
    height: 1px;
    background: #000000;
    position: absolute;
    top: 48%;
    left: 22%;
    transform: rotate(-45deg);
    transition: all 0.3s ease-out;
  }


  &:after {
    transform: rotate(45deg);
    transition: all 0.3s ease-out;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.10);

    &:before,
    &:after {
      transform: rotate(180deg);
    }
  }
}



