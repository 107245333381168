@use '../../../lib/colorvars';

// Checkbox variables
$checkbox-size: 20px;

.formCheck {
  display: inline-block;
  transform: translateZ(0); // Force 3d rendering
  box-sizing: border-box;

  // Hide native checkbox
  .checkbox {
    position: absolute;
    opacity: 0;
    margin: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;

    &:checked + label .icon svg path {
      stroke-dashoffset: 0;
      transition: all 350ms cubic-bezier(1, 0, .37, .91) !important;
    }

    &:hover + .icon {
      background-color: rgba(0, 0, 0, 0.05);
    }

    + label {
      display: flex;

      .icon {
        display: block;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        min-width: $checkbox-size;
        height: $checkbox-size;

        cursor: pointer;
        transition: all .2s ease;

        & svg {
          pointer-events: none;

          path {
            fill: none;
            stroke: #000;
            stroke-width: 1px;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 100;
            stroke-dashoffset: 101;
            transition: all 200ms ease;
          }
        }
      }

      .text {
        color: #{colorvars.$black};
        opacity: 0.4;
        font-size: 12px;
        line-height: 130%;
        font-weight: 400;
        letter-spacing: 0.24px;
        margin-top: 3px;
        margin-left: 10px !important;
      }
    }
  }

  &.error {
    .label {
      .icon {
        border-color: #{colorvars.$red};
      }
    }
  }

}
