.container {
  padding-top: 212px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headline {
  margin-bottom: 15px;
}

.loadingDescription {
  text-align: center;
}
