.loadingError {
  height: 35px;
  padding: 0 10px;
  display: flex;
  gap: 6px;
  align-items: center;
  background: rgba(0,0,0,0.3);
  border-radius: 8px;
  box-shadow: 0 3px 22px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.label {
  font-size: 13px;
  line-height: 17px;
  font-weight: 600;
  color: white;
  user-select: none;
  -webkit-user-select: none;
}

.okButton {
  background: none;
  border: 0;
  padding: 0;
  outline: 0;
  text-decoration: underline;
  color: white;
  cursor: pointer;
}
