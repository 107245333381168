.app {
  --appTopPadding: 44px;
  height: 100%;

  @media (min-width: 641px) and (min-height: 500px) {
    --appTopPadding: 49px;
  }

  @media (min-width: 1441px) {
    --appTopPadding: 53px;
  }
}

.map {
  .showingTutorial & {
    z-index: 1;
  }
}

.tutorialBackground {
  background: rgba(0,0,0,0.2);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  .showingTutorial & {
    z-index: 2;
  }
}

.waypoints, .routePropertyController, .detailedRouteFacts {
  z-index: 10;
}

.waypoints {
  position: absolute;
  top: var(--appTopPadding, 30px);
  left: 30px;
}

.routePropertyController {
  position: absolute;
  top: var(--appTopPadding, 30px);
  right: 30px;
}

.detailedRouteFacts {
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.routePropertyController, .detailedRouteFacts {
  transform: translateX(250%);
  transition: transform .35s ease-in-out;
}

.show {
  transform: translateX(0);
}

.loadingDisplay, .loadingError {
  position: absolute;
  top: var(--appTopPadding, 40px);
  left: 50%;
  z-index: 20;
  transform: translate3d(-50%, -100px, 0);
  transition: transform 500ms ease-in;
}

.notInitialized {
  transition: none;
}

.showLoadingUI {
  transform: translate3d(-50%, 0, 0);
  transition: transform 500ms ease-out;
}
