.container {
  padding-top: 122px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loadingIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 90px;
  margin-bottom: 20px;

  & :global(svg) {
    animation-name: rotate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.headline {
  margin-bottom: 15px;
}

.loadingDescription {
  text-align: center;
}
