@use '../../lib/colorvars';
@use 'sass:math';

$circle-width: 30px;
$circle-line-width: 3px;

.container {
  width: fit-content;
  min-width: 50px;
}

.circle {
  --iconPrimary: #{colorvars.$white};
  --iconSecondary: transparent;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  transform: scaleX(-1);

  &::before {
    display: block;
    width: $circle-width - $circle-line-width;
    height: $circle-width - $circle-line-width;

    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);

    content: '';
  }
}

.icon, .circleCanvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.icon {
  width: 25px;
  height: 25px;
}

.percentage, .variant {
  width: 100%;
  color: #{colorvars.$white};
  font-size: 13.5px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.01;
  text-align: center;
}

.variant {
  font-size: 11px;
  font-weight: 400;
}
