.container {
  width: 35px;
  height: 35px;

  // @TODO: Ask how the compass should behave
  //transition: .2s ease-out;
  transform: rotate(0deg);

  &:hover.rotated {
    cursor: pointer;
  }
}

.container :global(svg) {
  max-height: 100%;
  width: auto;
}
