@use '../../../lib/colorvars';

.root {
  position: relative;
  padding: 32px 30px 25px 30px;
  width: 360px;
  height: 600px;
  background-color: #{colorvars.$white};
}

.prefillWithSuggestion {
  margin-bottom: 8px;
}

.inputs {
  margin-bottom: 22px;
}

.routeImageSelector {
  margin-bottom: 25px;
}

.publish {
  margin-bottom: 8px;
}

.checkBox {
  margin-bottom: 22px;
}
