@use '../../lib/colorvars';

.container {
  --opacityActive: 1;
  --opacityHover: 0.75;
  --opacityInactive: 0.5;
  --opacityDisabled: 0.1;

  --iconPrimary: #{colorvars.$black};
  --iconSecondary: #{colorvars.$black};

  &.forest, &.forest:hover {
    --iconPrimary: #{colorvars.$white};
    --iconSecondary: #{colorvars.$forest};
  }

  &.mountain, &.mountain:hover {
    --iconPrimary: #{colorvars.$white};
    --iconSecondary: #{colorvars.$mountain};
  }

  &.country, &.country:hover {
    --iconPrimary: #{colorvars.$white};
    --iconSecondary: #{colorvars.$country};
  }

  &.shore, &.shore:hover {
    --iconPrimary: #{colorvars.$white};
    --iconSecondary: #{colorvars.$shore};
  }

  &:not(.active), &.disabled:hover {
    --iconPrimary: #{colorvars.$black};
    --iconSecondary: #{colorvars.$white};
  }

  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 57px;
  height: 65px;
  padding: 8px 0;
  border: 1px solid transparent;
  border-radius: 5px;
  background: colorvars.$white;
  transition: border-color 200ms ease-in-out;

  .iconContainer {
    width: 30px;
    height: 30px;
    opacity: var(--opacityInactive);
    transition: opacity 200ms ease-in-out;

    & svg {
      height: 100%;
      width: 100%;

      & path, & circle, & rect {
        transition: fill 500ms ease, stroke 200ms ease-in-out;
      }
    }
  }

  .text {
    color: colorvars.$black;
    font-size: 11px;
    font-weight: 400;
    line-height: 13.2px;
    letter-spacing: 0.02em;
    text-align: center;
    opacity: var(--opacityInactive);
    transition: opacity 0.5s ease-in-out;
  }

  &:hover {
    border-color: rgba(colorvars.$black, .1);
    cursor: pointer;

    .iconContainer, .text {
      opacity: var(--opacityHover);
    }
  }

  &.active {
    border-color: var(--iconSecondary);

    .iconContainer, .text {
      opacity: var(--opacityActive);
    }
  }

  &.disabled {
    .iconContainer, .text {
      opacity: var(--opacityDisabled);
    }

    &:hover {
      border-color: transparent;
      cursor: auto;
    }
  }
}
