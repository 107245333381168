@use '../../lib/colorvars';
@import './shared';

$bottomPlaceholder: 400px;

.sortableWrapper {
  position: relative;

  pointer-events: none;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    display: block;
    width: 100%;
    height: 40px;

    opacity: 1;
    background: linear-gradient(to bottom, colorvars.$white 37.5%, transparent 100%);

    transition: opacity 50ms linear;

    content: '';
    pointer-events: none;
  }

  &::after {
    top: unset;
    // bottom: calc($bottomPlaceholder);
    bottom: 0;
    transform: rotate(0.5turn);
  }
}

.sortableAtStart::before,
.sortableAtEnd::after {
  opacity: 0;
}

.sortableWrapperDragging {
  $bottomPlaceholder: 0px;
}

.sortable {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  // margin: 0 0 calc($bottomPlaceholder * -1);
  // padding: 0 0 $bottomPlaceholder;
  // width: calc($preferencesWidth + 80px);
  // max-height: calc(400px + $bottomPlaceholder);
  max-height: 400px;
}
