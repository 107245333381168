.container {
  height: 100%;
  padding-top: 122px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loadingIcon {
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 90px;
  margin-bottom: 20px;
}

.headline {
  flex-shrink: 1;
  margin-bottom: 15px;
}

.loadingDescription {
  flex-grow: 1;
  text-align: center;
}

.actions {
  flex-shrink: 1;
  width: 100%;
}
