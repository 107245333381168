@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal {
  position: fixed;
  display: none;
  pointer-events: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.35);

  &.show {
    display: block;
    opacity: 1;
    backdrop-filter: blur(10px);
    animation: fadeIn 0.3s ease-out;
  }
}
