@use '../../../lib/colorvars';

.mapboxgl-ctrl-bottom-left {
  display: flex;
  padding-left: var(--ui-elements-margin);
  padding-bottom: var(--ui-elements-margin);

  justify-content: flex-start;
  align-items: flex-end;

  > .mapboxgl-ctrl-scale {
    position: relative;

    margin: 0 0 0 var(--ui-elements-margin);
    padding: 0;
    border: 0;

    background: transparent;

    font-size: 9px;
    font-weight: 600;
    text-align: right;

    &::before {
      position: absolute;
      left: 0;
      bottom: 0;

      content: '0';
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 3px;

      border-radius: 2px;
      background: colorvars.$red;

      content: '';
    }
  }
}

.mapboxgl-ctrl-bottom-right a.mapboxgl-ctrl-logo {
  margin: 0;
  margin-bottom: calc( var(--ui-elements-margin) - 34px);
  // needs to be -9px for (max-width: 1349px) and -4px for bigger screens
}

.has_route .mapboxgl-ctrl-bottom-right a.mapboxgl-ctrl-logo {
  margin-right: calc( 2 * var(--ui-elements-margin) + 280px - 10px );
  // 280px is the width of the route display, 10px is a margin on ".mapbox-ctrl"-Layer,
  // two times ui-elements-margin because one to the right border and one between logo
  // and route display
}

.mapbox-controls {
  grid-area: controls;
}
