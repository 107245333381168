@use '../../lib/colorvars';
@use 'sass:color';

.sliderContainer {
  padding: 0 24px;
}

.slider {
  width: 100%;

  -webkit-appearance: none;
  appearance: none;
  border-radius: 1.5rem;

  cursor: pointer;

  &::-webkit-slider-container {
    display: block;
    height: 100%;
  }

  &::-webkit-slider-runnable-track {
    height: 2px;

    border-radius: 2px;
    background: colorvars.$white;
  }

  // This needs to be duplicated, because Chrome does not allow comma-separated selectors here
  &::-moz-range-track {
    height: 2px;

    border-radius: 2px;
    background: colorvars.$white;
  }

  &::-webkit-slider-thumb {
    position: relative;
    z-index: 2;

    padding: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    outline: 1px solid #74beac;

    -webkit-appearance: none;
    appearance: none;
    border-radius: 20px;
    background: colorvars.$white;
    background-clip: content-box;

    transform: translate(0, -50%);
  }

  // This needs to be duplicated, because Chrome does not allow comma-separated selectors here
  &::-moz-range-thumb {
    position: relative;
    z-index: 2;

    padding: 0;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    outline: 1px solid #74beac;

    -webkit-appearance: none;
    appearance: none;
    border-radius: 20px;
    background: colorvars.$white;
    background-clip: content-box;

    transform: translate(0, 0);
  }
}

.labels {
  display: flex;
  justify-content: space-between;

  color: colorvars.$white;
  font-size: 13.5px;
  line-height: 21px;
  font-weight: 600;
}
